import axios from 'axios';

const baseUrl = 'https://us-central1-budgeting-website.cloudfunctions.net';

const standardConfig = {
  headers: {
    'Content-Type': 'application/json',
  }
}



export const getLinkPublicTokens = (familyId) => {
  console.log('Hit');
  console.log(familyId);
  axios.post(baseUrl + '/publicLinkTokens', {familyId}).then(response => {
    console.log(response);
  }).catch(error => {
    console.error(error);
  })
}
