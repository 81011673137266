import LoginComponent from '../pages/Login/Login';
import ContainerListComponent from '../pages/containerList/ContainerList';
import ContainerControlsComponent from '../pages/containerControls/ContainerControls';
import TransactionControlsComponent from '../pages/transactionControls/TransactionControls';
import DepositComponent from '../pages/deposit/Deposit';
const Routes = {
  login: LoginComponent,
  containerList: ContainerListComponent,
  containerControls: ContainerControlsComponent,
  transactionControls: TransactionControlsComponent,
  deposit: DepositComponent
}

export default Routes;
