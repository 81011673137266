import React from 'react';
import ContainerPickerComponent from './ContainerPicker/ContainerPicker';
import {makeTransfer} from '../../../utils/FirebaseUtils';
import {roundToDec} from '../../../utils/Rounder';

class TransactionControlsComponent extends React.Component {
  constructor(props){
    super();
    if(props.metadata.from == null && props.metadata.to == null){
      alert('An error occurred! If this persists, contact us.');
      props.changePage('containerList');
    }

    this.state = {
      description: '',
      amount: '',
      toSelectable: props.metadata.to === undefined,
      fromSelectable: props.metadata.from === undefined,
      to: props.metadata.to,
      from: props.metadata.from,
      working: false
    }
  }

  async submit(){
    this.setState({
      working: true
    })
    await makeTransfer(this.state.from, this.state.to, roundToDec(parseFloat(this.state.amount)), this.state.description, this.props.userState.familyId);
    this.props.changePage('containerList');
  }

  calculateMax(fromContainer, toContainer){
    let toMax = 0;
    if(!toContainer){
      toMax = 1000000;
    }
    else if(toContainer.maxAmount <= 0){
      toMax = 1000000;
    }
    else{
      toMax = toContainer.maxAmount - toContainer.amount;
    }
    let fromMax = 1000000;
    if(!fromContainer){
      fromMax = 1000000;
    }
    else{
      fromMax = fromContainer.amount;
    }
    return roundToDec(Math.min(toMax, fromMax));
  }

  getInputErrorMessage(){
    let floatNum = parseFloat(this.state.amount);
    if(!floatNum){
      return 'Must enter an amount';
    }
    floatNum = roundToDec(floatNum);
    if(floatNum > this.calculateMax(this.state.from, this.state.to)){
      return 'Amount is too high';
    }
    if(floatNum <= 0){
      return 'Amount must be positive';
    }
    if(!this.state.description){
      return 'Must enter a description';
    }
    if(!this.state.from && this.state.fromSelectable){
      return 'Must enter a container to take from';
    }
    if(!this.state.to && this.state.toSelectable){
      return 'Must enter a container to add to';
    }
  }

  render(){
    if(this.state.working){
      return (
        <div className="flexPage">
          <div className="flexPageSection breathingRoom" style={{textAlign: 'center'}}>
            Working...
          </div>
        </div>
      )
    }
    return (
      <div className="flexPage">
        <div className="flexPageSection breathingRoom" style={{textAlign: 'center'}}>
          <div className="flexPageSectionTitle">
            Transaction Details
          </div>
          <div>
            Moving Money From:
            <br />
            <ContainerPickerComponent
            selectable={this.state.fromSelectable}
            container={this.props.metadata.from || this.state.from}
            containers={this.props.metadata.containers}
            select={selectedContainer => this.setState({from: selectedContainer, amount: ''})}
            />
          </div>
          <div>
            Moving Money To:
            <br />
            <ContainerPickerComponent
            selectable={this.state.toSelectable}
            container={this.props.metadata.to || this.state.to}
            containers={this.props.metadata.containers}
            select={selectedContainer => this.setState({to: selectedContainer, amount: ''})}
            />
          </div>
          Description:
          <input onChange={e => this.setState({description: e.target.value})} />
          Amount: (Max = ${this.calculateMax(this.state.from, this.state.to)})
          <input onChange={e => this.setState({amount: e.target.value})} />
          {this.getInputErrorMessage()}
          <button disabled={this.getInputErrorMessage()} onClick={() => this.submit()}>
            Submit
          </button>
          <button onClick={() => this.props.changePage('containerList')}>
            Cancel
          </button>
        </div>
      </div>
    )
  }
}

export default TransactionControlsComponent;
