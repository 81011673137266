import React from 'react';
import Routes from './Routes';

class RouterComponent extends React.Component {

  constructor(){
    super();
    this.state = {
      activePage: 'login',
      metadata: {},
      userState: {}
    }
  }

  changePage(activePage, metadata = {}){
    this.setState({
      activePage,
      metadata
    })
  }

  setUserState(userState){
    this.setState({
      userState
    });
  }

  render() {
    const Wrapper = Routes[this.state.activePage]
    return (
      <div>
        <Wrapper changePage={this.changePage.bind(this)} setUserState={this.setUserState.bind(this)} metadata={this.state.metadata} userState={this.state.userState}/>
      </div>
    )
  }
}

export default RouterComponent;
