import Firebase from 'firebase';
import {roundToDec} from './Rounder';
export async function fetchUserReference(email){
  let data = await Firebase.firestore().collection('users').where('email', '==', email).get();
  return data.docs[0].id;
}

export async function fetchFamilyIds(email){
  let userId = await fetchUserReference(email);
  let data = await Firebase.firestore().collection('users').doc(userId).get();
  data = data.data().families;
  return data;
}

export async function fetchAllContainers(family){
  let containers = await Firebase.firestore().collection('families').doc(family).collection('containers').get();
  containers = containers.docs.map(doc => {
    return {
      ...doc.data(),
      id: doc.id
    }
  });
  return containers;
}

export async function makeTransfer(from, to, amount, description, family){
  //ensure amount is a double rounded
  amount = roundToDec(amount);
  await Firebase.firestore().collection('families').doc(family).collection('transactions').add({
    amount,
    description,
    from: from ? from.name : null,
    to: to ? to.name : null,
    date: new Date()
  });
  if(from){
    //Take from
    from.amount -= amount;
    from.amount = roundToDec(from.amount);
    await Firebase.firestore().collection('families').doc(family).collection('containers').doc(from.id).set(from);
  }
  if(to){
    //Add to
    to.amount += amount;
    to.amount = roundToDec(to.amount);
    await Firebase.firestore().collection('families').doc(family).collection('containers').doc(to.id).set(to);
  }
}

export async function makeDeposit(remainingDeposit, description, containersList, family){
  let envelopes = containersList.filter(container => container.maxAmount >= 0).sort((x, y) => x.fillPriority - y.fillPriority);
  //Jars must have priority to be filled
  let jars = containersList.filter(container => container.maxAmount < 0 && container.fillPriority > 0);

   //Deposit into Envelopes
   for (let envelope of envelopes) {
     if(envelope.maxAmount - envelope.amount <= remainingDeposit){
       remainingDeposit -= (envelope.maxAmount - envelope.amount);
       console.log(remainingDeposit);
       await makeTransfer(null, envelope, envelope.maxAmount - envelope.amount, description, family)
     }
     else if(remainingDeposit > 0){
       await makeTransfer(null, envelope, remainingDeposit, description, family)
       remainingDeposit = 0;
     }
   };

   //Split remaining among jars
   if(remainingDeposit > 0){
     console.log(remainingDeposit);
     let amountAddedForEach = remainingDeposit / jars.length;
     console.log(amountAddedForEach);
     for (let jar of jars) {
       await makeTransfer(null, jar, amountAddedForEach, description, family);
     }
   }
}
