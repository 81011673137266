import React from 'react';
import LinkButtonComponent from '../../units/link/LinkButton';
import { fetchAllContainers } from '../../../utils/FirebaseUtils'
import { getLinkPublicTokens } from '../../../utils/FirebaseFunctions'
import { roundToDec } from '../../../utils/Rounder'
import './ContainerList.css'

class ContainerListComponent extends React.Component {
  constructor(){
    super();
    this.state = {
      containers: [],
      loading: true
    }
  }

  componentDidMount(){
    if(this.props.metadata.containers){
      this.setState({
        containers: this.props.metadata.containers,
        loading: false
      });
    }
    else{
      this.loadContainers();
    }
    getLinkPublicTokens(this.props.userState.familyId);
  }

  async loadContainers(){
    let containers = await fetchAllContainers(this.props.userState.familyId);
    this.setState({
      containers,
      loading: false
    })
  }

  render() {
    if(this.state.loading){
      return (
        <div className="verticalCenter">
          Loading...
        </div>
      )
    }
    return (
      <div className='flexPage'>
        <div className='flexPageSection'>
          <span style={{textAlign: 'center'}}>
            Envelopes
          </span>
          {
            this.state.containers
            .filter(container => container.maxAmount >= 0)
            .map(container => {
              return (
                <div key={container.name} className="controlButton" onClick={() =>
                  this.props.changePage('containerControls', {
                    containers: this.state.containers,
                    container: container
                  })
                }>
                  {container.name}
                  <span className="amountDisplay">
                    ${container.amount} / ${container.maxAmount}
                  </span>
                </div>
                )
              })
            }
        </div>
        <div className='flexPageSection'>
          <span style={{textAlign: 'center'}}>
            Jars
          </span>
          {
            this.state.containers
            .filter(container => container.maxAmount < 0)
            .map(container => {
              return (
                <div key={container.name} className="controlButton" onClick={() =>
                  this.props.changePage('containerControls', {
                    containers: this.state.containers,
                    container: container
                  })
                }>
                  {container.name}
                  <span className="amountDisplay">
                    ${container.amount}
                  </span>
                </div>
                )
              })
            }
        </div>
        <div className="flexPageSection">
        <div className="flexPageSectionTitle">
        Overview
        </div>
          <div className="flexPageSectionTitle">
            Total: ${roundToDec(this.state.containers.map(container => container.amount).reduce((oldAmount, amount) => oldAmount + amount))}
          </div>
          <div className="flexPageSectionTitle">
            Spendable: ${roundToDec(this.state.containers.filter(container => container.contributesToSpendable).map(container => container.amount).reduce((oldAmount, amount) => oldAmount + amount))}
          </div>
          <div className="controlButton" onClick={() => this.props.changePage('deposit', {containers: this.state.containers})}>
            Make Deposit
          </div>
          <LinkButtonComponent />
        </div>
      </div>
    );
  }
}

export default ContainerListComponent;
