import React from 'react';
import './ContainerControls.css';

class ContainerControlsComponent extends React.Component {

  constructor(props){
    super();
    let containerType = '';
    if(props.metadata.container.maxAmount >= 0){
      containerType = 'envelope';
    }
    else{
      containerType = 'jar'
    }

    this.state = {
      containerType
    }
  }

  openTransaction(from, to){
    this.props.changePage(
      'transactionControls',
      {
        from,
        to,
        containers: this.props.metadata.containers
      }
    )
  }

  render(){
    let amountDisplay = '';
    if(this.state.containerType === 'envelope'){
      amountDisplay = `$${this.props.metadata.container.amount} / $${this.props.metadata.container.maxAmount}`
    }
    if(this.state.containerType === 'jar'){
      amountDisplay = `$${this.props.metadata.container.amount}`
    }
    return (
      <div className="flexPage">
        <div className="flexPageSection">
          <div className="flexPageSectionTitle">
            {this.props.metadata.container.name}
          </div>
          <div className="flexPageSectionTitle">
            {amountDisplay}
          </div>
          <div className="controlButton" onClick={() => this.openTransaction(null, this.props.metadata.container)}>
            Make deposit into {this.props.metadata.container.name}
          </div>
          <div className="controlButton" onClick={() => this.openTransaction(this.props.metadata.container, null)}>
            Make purchase using {this.props.metadata.container.name}
          </div>
          <div className="controlButton" onClick={() => this.openTransaction(this.props.metadata.container, undefined)}>
            Transfer from {this.props.metadata.container.name}
          </div>
          <div className="controlButton" onClick={() => this.openTransaction(undefined, this.props.metadata.container)}>
            Transfer to {this.props.metadata.container.name}
          </div>
          <div className="controlButton" onClick={() => this.props.changePage(
            'containerList',
            {
              containers: this.props.metadata.containers
            }
          )}>
            Return to main menu
          </div>
        </div>
      </div>
    )
  }
}

export default ContainerControlsComponent;
