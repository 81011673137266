import React from 'react';
import Firebase from 'firebase';
import RouterComponent from './components/Router/Router';
import './static/GlobalStyles.css';
function App() {

  Firebase.initializeApp({
  apiKey: ' AIzaSyA6bNmrGqJMBcDDtvqUExJ0thzmAC2zNKs ',
  authDomain: '### FIREBASE AUTH DOMAIN ###',
  projectId: 'budgeting-website'
  });

  window.addEventListener("beforeunload", (ev) =>
  {
    Firebase.auth().signOut();
  });

  return (
    <div style={{}} className='global'>
      <RouterComponent />
    </div>
  );
}

export default App;
