import React from 'react';
import './Login.css';
import Firebase from 'firebase';
import { fetchFamilyIds } from '../../../utils/FirebaseUtils';

class LoginPageComponent extends React.Component {
  constructor(){
    super();
    this.state = {
      username: '',
      password: '',
      errorMessage: ''
    }
  }

  login(){
    let setError = (errorMessage) => {
      this.setState({
        errorMessage
      })
    }
    setError('');
    setError = setError.bind(this);
    Firebase.auth().signInWithEmailAndPassword(this.state.username, this.state.password)
      .then(data => this.handleLogin(data))
      .catch(function(error) {
        var errorMessage = error.message;
        setError(errorMessage);
      });
  }

  async handleLogin(data){
    let email = data.user.email;
    let familyId = (await fetchFamilyIds(email))[0].trim();
    this.props.setUserState({
      //TODO add family Id
      //TODO add family specific security check on rules
      email,
      familyId
    })
    this.props.changePage('containerList');
  }

  render() {
    return (
      <div className='loginWrapper'>
        Email
        <input onChange={(event) => this.setState({username: event.target.value})}/>
        Password
        <input type='password' onChange={(event) => this.setState({password: event.target.value})} onKeyDown={e => e.key === 'Enter' && this.login()}/>
        <button onClick={() => this.login()}>
          Login
        </button>
        <span style={{color: 'red'}}>
          {this.state.errorMessage}
        </span>
      </div>
    );
  }
}

export default LoginPageComponent;
