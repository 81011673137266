import React from 'react';
import {makeDeposit} from '../../../utils/FirebaseUtils';
import {roundToDec} from '../../../utils/Rounder';

class DepositComponent extends React.Component {

  constructor(){
    super();
    this.state = {
      amount: '',
      description: '',
      working: false
    }
  }

  async submit(){
    this.setState({working: true});
    await makeDeposit(roundToDec(parseFloat(this.state.amount)), this.state.description, this.props.metadata.containers, this.props.userState.familyId);
    this.props.changePage('containerList');
  }

  getInputErrorMessage(){
    if(!this.state.description){
      return 'Must enter a description';
    }
    let amountNum = parseFloat(this.state.amount);
    if(!amountNum){
      return 'Must input a valid number';
    }
    if(amountNum <= 0){
      return 'Must input a positive number';
    }
  }

  render(){
    if(this.state.working){
      return (
        <div className="flexPage">
          <div className="flexPageSection breathingRoom" style={{textAlign: 'center'}}>
            Working...
          </div>
        </div>
      )
    }
    return (
      <div className='flexPage'>
        <div className='flexPageSection'>
          <span className='flexPageSectionTitle'>
            Make Deposit:
          </span>
          <div>
            Description
            <input onChange={e => this.setState({description: e.target.value})} />
          </div>
          <div>
            Amount
            <input onChange={e => this.setState({amount: e.target.value})} />
          </div>
          {this.getInputErrorMessage()}
          <button onClick={() => this.submit()}>
            Submit
          </button>
          <button onClick={() => this.props.changePage('containerList')}>
            Cancel
          </button>
        </div>
      </div>
    )
  }
}

export default DepositComponent;
