import React from 'react';

class ContainerPickerComponent extends React.Component {
  render(){
    if(!this.props.selectable){
      if(this.props.container == null){
        return (
          <div>
            THE UNIVERSE
          </div>
        )
      }
      else{
        return (
          <div>
            {this.props.container.name}
            <br/>
            Amount: ${this.props.container.amount}
          </div>
        )
      }
    }
    else{
      return (
        <div>
          <select onChange={e => this.props.select(this.props.containers.find(container => container.name === e.target.value))}>
            <option></option>
            {this.props.containers.map(container => {
              return (
                <option key={container.name}>
                  {container.name}
                </option>
                )
              })}
          </select>
          {this.props.container && (
            <div>
              Amount: ${this.props.container.amount}
            </div>
          )}
        </div>
      )
    }
  }
}

export default ContainerPickerComponent;
