import React from 'react';
import PlaidLink from 'react-plaid-link'
class LinkButtonComponent extends React.Component {
  render(){
    //Currently public-development-271b8572-4d92-4cc8-81e7-9d9d7f4811e6
    return (
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <PlaidLink
          className=".div controlButton"
          style={{}}
          clientName="Flat Bank"
          env="development"
          product={["transactions"]}
          publicKey="54c73b6d969da4ef70901fcdd8a973"
          onExit={() => console.log("Exit")}
          onSuccess={this.handleLinkData}>
          Link your bank account
        </PlaidLink>
      </div>

    )
  }

  handleLinkData(token, metadata){
    console.log("Worked!");
    console.log(token);
    console.log(metadata);
  }
}

export default LinkButtonComponent;
